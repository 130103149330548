<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		title="Administration des organisations"
		isBackButtonHidden
		>
			<ButtonSlot
			v-if="$hasRight('organizations.create')"
			@click="isCreateOrganizationModaleOpened = true"
			>
				Nouvelle organisation
			</ButtonSlot>
		</HeaderSlot>

		<div class="tw-flex tw-gap-[10px] tw-h-[50px]">
			<v-text-field
			class="tw-max-w-[350px]"
			append-icon="mdi-magnify"
			clearable
			hide-details
			label="Rechercher une organisation"
			v-model="fn"
			/>

			<v-select
			class="tw-max-w-[275px]"
			clearable
			hide-details
			label="Filtrer par type"
			:items="organizationTypes"
			item-value="value"
			item-text="name"
			v-model="ft"
			/>
		</div>

		<div class="tw-flex tw-grow tw-overflow-y-auto tw-flex-col tw-gap-[10px] tw-pr-[5px] tw-w-full">
			<div
			class="card tw-p-[15px] tw-flex tw-items-center tw-justify-between tw-cursor-pointer"
			v-for="organization in filterOrganizations"
			:key="organization.id"
			@click="redirectToOrganizationView(organization.id)"
			>
				<div class="orgName tw-transition-[scale]">
					{{ organization.name }}
				</div>

				<v-chip v-if="user.organizationId === organization.id">
					Vous faites partie de cette organisation
				</v-chip>
			</div>
		</div>

		<CreateOrganizationModale
		v-if="isCreateOrganizationModaleOpened"
		@closePopin="isCreateOrganizationModaleOpened = false"
		@organizationCreated="handleNewOrganization"
		/>
	</div>
</template>

<script>
import CreateOrganizationModale from "@/components/entities/organization/CreateOrganization.modal";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
	components: {
		CreateOrganizationModale
	},
	data(){
		return {
			isCreateOrganizationModaleOpened: false,
			organizationTypes: [
				{name: "Bureau d'étude", value: true},
				{name: "Client", value: false}
			]
		};
	},
	computed: {
		...mapGetters("user", ["user"]),
		...mapGetters("organizations", [
			"organizations", "filterName", "filterOrganizations", "filterType"
		]),

		fn: {
			get(){
				return this.filterName;
			},

			set(value){
				this.SET_FILTERNAME(value);
			}
		},

		ft: {
			get(){
				return this.filterType;
			},

			set(value){
				this.SET_FILTERTYPE(value);
			}
		},
	},
	methods: {
		...mapActions("user", ["waitLoadUser"]),
		...mapActions("organizations", ["initStore"]),
		...mapMutations("organizations", ["SET_FILTERNAME", "SET_FILTERTYPE"]),

		redirectToOrganizationView(id){
			this.$router.push({
				name: "organization-administration",
				params: {id}
			});
		},

		handleNewOrganization(organization){
			this.redirectToOrganizationView(organization.id);
		}
	},
	async mounted(){
		await this.waitLoadUser();

		if(!this.$hasRight("organizations.manageAll")) this.$router.push({path: "/workspaces"});

		await this.initStore();
		console.log(this.organizations);
	}
};
</script>

<style lang="scss" scoped>

.card{
	user-select: none;
    border-radius: 4px;
    border: solid rgba(0,0,0,0.12) 1px;

	&:hover > .orgName{
		scale: 1.1;
	}

	.orgName{
		transform-origin: center left;
	}
}


</style>
